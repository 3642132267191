import classNames from 'classnames';
import { html, TemplateResult } from 'lit-element';
import styles from './ui.css';

interface IText {
    text: string;
}

export const Title = (options: IText) =>
    html`<h1 class="${styles.title}">${options.text}</h1>`;

export const Subtitle = (options: IText) =>
    html`<h2 class="${styles.subtitle}">${options.text}</h2>`;

export const Text = (options: IText) =>
    html`<p class="${styles.text}">${options.text}</p>`;

const Item = (options: IText) => html`
    <li class="${styles.listItem}">
        <span
            class="iconify ${styles.listItemIcon}"
            data-icon="emojione-monotone:white-heavy-check-mark"
        ></span>
        <div>${options.text}</div>
    </li>
`;

interface IList {
    list: string[];
}

export const List = (options: IList) => html`
    <ul class="${styles.list}">
        ${options.list.map((text) => Item({ text }))}
    </ul>
`;

interface IButton {
    text: string;
    alternate: boolean;
    onClick: Function;
}

export const Button = (options: IButton) => html`
    <a
        class="${styles.button} ${options.alternate && styles.buttonAlternate}"
        @click="${options.onClick}"
        >${options.text}</a
    >
`;

interface IFormButton {
    text: string;
    alternate: boolean;
    type: 'submit' | 'reset';
}

export const FormButton = (options: IFormButton) => html`
    <input
        class="${styles.formButton} ${options.alternate && styles.buttonAlternate}"
        type="${options.type}"
        value="${options.text}"
    />
`;

export const GhostButton = (options: IButton) => html`
    <a
        class="${styles.ghostButton} ${options.alternate && styles.buttonAlternate}"
        @click="${options.onClick}"
        >${options.text}</a
    >
`;

interface IScrollButtons {
    isVisible: boolean;
    upAction: Function;
    downAction: Function;
}

export const ScrollButtons = (options: IScrollButtons) => {
    const classes = classNames([
        styles.scrollButtons,
        { [styles.scrollButtonsHidden]: options.isVisible },
    ]);
    return html`
        <div class="${styles.scrollButtonsWrapper}">
            <div class="${classes}">
                <div class="${styles.scrollButton}" @click="${options.upAction}">
                    <span class="iconify" data-icon="ph:caret-up"></span>
                </div>
                <div class="${styles.scrollButton}" @click="${options.downAction}">
                    <span class="iconify" data-icon="ph:caret-down"></span>
                </div>
            </div>
        </div>
    `;
};

interface ICard {}

export const Card = (_options: ICard, children: TemplateResult[]) => html`
    <div class="${styles.card}">${children}</div>
`;

interface ITopic {
    content: TemplateResult | TemplateResult[];
}

export const Topic = (options: ITopic) => html`
    <div class="${styles.topic}">${options.content}</div>
`;

interface IScrollHint {
    isVisible: boolean;
    action: Function;
}

export const ScrollHint = (options: IScrollHint) => {
    const classes = classNames([
        styles.scrollHint,
        { [styles.scrollHintHidden]: !options.isVisible },
    ]);
    return html`
        <div class="${classes}" @click="${options.action}">
            <div class="${styles.scrollHintIcon}">
                <div class="${styles.scrollHintPivot}">
                    <span class="iconify" data-icon="ph:caret-double-down"></span>
                </div>
            </div>
        </div>
    `;
};
