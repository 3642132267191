import { Popup } from './components/popup';
import styles from './hub-website.css';
import { PrivacyText } from './privacy';
import { html, customElement, property } from 'lit-element';
import { LightElement } from './base-component';
import { buildUI, IBlockEvent, triggerEvent } from './components';
import { siteData } from './data';

@customElement('hub-website')
export class HubWebsite extends LightElement {
    @property()
    isPopupOpen: boolean = false;

    @property()
    isPrivacyPopupOpen: boolean = false;

    @property()
    isSandboxPopupOpen: boolean = false;

    connectedCallback() {
        super.connectedCallback();
        this.addEventListener('block-event', this.handleBlockEvent);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this.addEventListener('block-event', this.handleBlockEvent);
    }

    private handleBlockEvent = (e: CustomEvent) => {
        const blockEvent: IBlockEvent = e.detail;
        switch (blockEvent.type) {
            case 'SWITCH':
                this.sendGoToEvent();
                break;
            case 'POPUP':
                this.handleBlockEventPopup(blockEvent);
                break;
        }
    };

    private handleBlockEventPopup(blockEvent: IBlockEvent) {
        const { popup, action } = blockEvent.payload;
        const popupActionMap = {
            CONTACTS: {
                OPEN: () => (this.isPopupOpen = true),
                CLOSE: () => (this.isPopupOpen = false),
            },
            PRIVACY: {
                OPEN: () => (this.isPrivacyPopupOpen = true),
                CLOSE: () => (this.isPrivacyPopupOpen = false),
            },
            TRY_SANDBOX: {
                OPEN: () => (this.isSandboxPopupOpen = true),
                CLOSE: () => (this.isSandboxPopupOpen = false),
            },
        };
        const nullAction = () => null;
        const popupMap = popupActionMap[popup] || {};
        const popupAction = popupMap[action] || nullAction;
        popupAction();
    }

    private sendGoToEvent() {
        this.dispatchEvent(
            new CustomEvent('hub-go-to', {
                detail: { target: 'app' },
                bubbles: true,
                composed: true,
            })
        );
    }

    render() {
        const layoutComponents = buildUI(siteData);
        return html`
            <div id="app" class="${styles.app}">${layoutComponents}</div>
            ${this.renderContactFormPopup()} ${this.renderPrivacyTextPopup()}
            ${this.renderSandboxTextPopup()}
        `;
    }

    private renderContactFormPopup() {
        return Popup(
            html`<wizard-embed token="hxHYfskFPZ9iCQ0Q5QOb_hubaqosito"></wizard-embed>`,
            this.isPopupOpen,
            (e: Event) =>
                triggerEvent(e.target, {
                    type: 'POPUP',
                    payload: { popup: 'CONTACTS', action: 'CLOSE' },
                })
        );
    }

    private renderPrivacyTextPopup() {
        return Popup(PrivacyText(), this.isPrivacyPopupOpen, (e: Event) =>
            triggerEvent(e.target, {
                type: 'POPUP',
                payload: { popup: 'PRIVACY', action: 'CLOSE' },
            })
        );
    }

    private renderSandboxTextPopup() {
        return Popup(
            html`<wizard-embed
                token="hxHYfskFPZ9iCQ0Q5QOb_hubaqosandbox"
            ></wizard-embed>`,
            this.isSandboxPopupOpen,
            (e: Event) =>
                triggerEvent(e.target, {
                    type: 'POPUP',
                    payload: { popup: 'TRY_SANDBOX', action: 'CLOSE' },
                })
        );
    }
}
